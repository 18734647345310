import { useEffect } from "react";
import mixpanel from "mixpanel-browser";
import {Route, Routes} from "react-router-dom";
import Layout from "./layout/Layout";
import styles from "./App.module.scss";
import Home from "pages/Home/Home";
import Articles from "pages/Articles/Articles";
import { initClient } from "./contentfulHelper";
import Article from "./pages/Articles/Article";
import Livechat from "components/LiveChat/LiveChat";


function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      mixpanel.init("f08b3b1c4a2fc9e2c7cc014333cc9233");
    } else {
      mixpanel.init("729ace78d0aeb71ba633741d8c92a9ca");
    }
  }, []);

  initClient("gm0z633qcp6l", "0ScOA13RQeFDTgPTn-4qOjlWkHY8tMUQL-n41u2b8Yo");

  return (
    <>
      <Livechat />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<Home/>} />
          <Route path="articles" element={<Articles/>} />
          <Route path="articles/:slug" element={<Article/>} />
          <Route path="*" element={<div className={styles.notFoundPageWrapper}><h1>Oh no... 404 Not Found</h1></div>} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
