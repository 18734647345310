import styles from "../App.module.scss";
import { useEffect } from "react";
import mixpanel from "mixpanel-browser";
import Footer from "features/footer/Footer";
import {Outlet} from "react-router-dom";
import { ReactComponent as TorqLogo } from "images/torq-logo-black.svg";
import {Link} from "react-router-dom";


function Layout() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      mixpanel.init("f08b3b1c4a2fc9e2c7cc014333cc9233");
    } else {
      mixpanel.init("729ace78d0aeb71ba633741d8c92a9ca");
    }
  }, []);


  return (
    <div className={styles.app}>
      <div className={styles.siteWrapper}>
        <Link className={styles.logo} to={"/"}>
          <TorqLogo />
        </Link>
        <Outlet />
        <Footer/>
    </div>
    </div>
  );
}

export default Layout;
