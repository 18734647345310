import styles from "./card.module.scss";
import classNames from "classnames";
import {ReactElement} from "react";

type cardProps = {
  title: string | ReactElement
  content: string;
  cardFigure?: ReactElement;
  className?: string;
};

export default function Card(props: cardProps) {
  return (
      <div className={styles.cardWrapper}>
        <div className={styles.cardContainer}>
          <header className={styles.cardHeader}>
            <div className={styles.title}>{props.title}</div>
            <div className={styles.content}>{props.content}</div>
          </header>
        </div>
        <div
          className={classNames(styles.cardFigure)}
        >
          {props.cardFigure}
        </div>
      </div>
  );
}
