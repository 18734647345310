import styles from "./FloatyMenu.module.scss";
import {ReactNode, useState} from "react";
import classNames from "classnames";
import {Link, LinkProps} from "react-router-dom";
import {ReactComponent as MenuToggle} from "images/menuToggler.svg";
import {ReactComponent as CloseToggle} from "images/close.svg";


export default function FloatyMenu() {

  // Toggle the menu
  const [expanded, setExpanded] = useState(false);

  // Check if the menu is at the root page
  const isRoot = window.location.pathname === "/";

  return (
    <FloatyMenuWrapper expanded={expanded} setExpanded={setExpanded}>
      {!isRoot && <MenuItem text={"Home"} to={"/"}/>}
      {isRoot && <ExternalMenuItem text={"Home"} link={"/#home"} onClick={() => setExpanded(false)} /> }
      {/*<ExternalMenuItem text={"Demo"} link={"#demo"} />*/}
      <MenuItem text={"Articles"} to={"/articles"} />
      {/*<ExternalMenuItem text={"Price"} link={"/#price"} onClick={() => setExpanded(false)}  />*/}
      {/*<ExternalMenuItem text={"Docs"} link={"https://docs.torq.co"} target={"_blank"}/>*/}
      <ExternalMenuItem text={"Get Started"} inverted={true} link={"/#get-started"} onClick={() => setExpanded(false)} />
    </FloatyMenuWrapper>
  )
}


type FloatyMenuProps = {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  children: ReactNode
}

export function FloatyMenuWrapper(props: FloatyMenuProps) {

  return (
    <div className={classNames(styles.floatyMenuWrapper, {[styles.expanded]: props.expanded})}>
      <div className={styles.floatyMenuToggle} onClick={() => props.setExpanded(!props.expanded)}>
        {props.expanded && <CloseToggle/>}
        {!props.expanded && <MenuToggle/>}
        {props.expanded ? "Close" : "Menu"}
      </div>
      <div className={styles.floatyMenuContainer}>
        {props.children}
      </div>
    </div>
  );
}


type ExternalMenuItemProps = {
  text: string;
  link: string;
  target?: string;
  inverted?: boolean;
  onClick?: () => void;
}

export function ExternalMenuItem(props: ExternalMenuItemProps) {
  return (
    <a className={classNames(styles.menuItemWrapper, {[styles.inverted]: props.inverted})} href={props.link} rel={"noreferrer"} target={props.target} onClick={props.onClick}>
      {props.text}
    </a>
  );
}

type MenuItemProps = {
  text?: string;
  image?: string;
} & LinkProps;

export function MenuItem({ text, image, ...aProps }: MenuItemProps) {
  return (
    <div className={styles.menuItemWrapper}>
      <Link {...aProps}>
        {image && <img src={image} alt={text} className={styles.image} />}
        {text && <span>{text}</span>}
      </Link>
    </div>
  );
}