import { createClient, ContentfulClientApi } from 'contentful'

let client: ContentfulClientApi<undefined>;
let authorized: boolean;

export function initClient(spaceId: string, accessToken: string) {
  client = createClient({
    space: spaceId,
    accessToken: accessToken,
    host: 'cdn.contentful.com',
    timeout: 10000,
  })
  return client.getSpace()
    .then((space) => {
      authorized = true
      return space
    })
}

export function useContentful() {
  return {authorized, client}
}