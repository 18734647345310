import styles from "./articles.module.scss";
import {useContentful} from "contentfulHelper";
import {useEffect, useState} from "react";
import {EntryFields, EntrySkeletonType } from "contentful";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {useParams} from "react-router-dom";
import FloatyMenu from "components/floatyMenu/FloatyMenu";

type Category = {category: string}

type Article = {
  title: EntryFields.Text;
  category: EntryFields.EntryLink<EntrySkeletonType<Category>>;
  slug: EntryFields.Text;
  excerpt: EntryFields.RichText;
  content: EntryFields.RichText;
  thumbnail: EntryFields.AssetLink;
  createdAt: EntryFields.Date;
}

type ArticleQuery = EntrySkeletonType<Article>


export default function Article() {
  const {authorized: articlesLoaded, client } = useContentful()
  const [article, setArticle] = useState<Article | undefined>()
  const [loading, setLoading] = useState(true)
  const [errorLoading, setErrorLoading] = useState(false)
  const { slug } = useParams<{ slug: string }>();

    useEffect(() => {
    const res = client.getEntries<ArticleQuery>({
      content_type: "article"
    })
    res.then((res) => {
      setArticle(res.items.find((item) => {
        return item.fields.slug === slug;
      })?.fields)
    }).catch((err) => {
      setErrorLoading(true)
      console.log(err)
    }).finally(() => {
      setLoading(false)
    })
  }, [articlesLoaded])

  return (
      <article className={styles.articleWrapper}>
        <FloatyMenu/>
        <header className={styles.articleHeaderSection}>
          <div className={styles.articleHeaderContainer}>
            <div className={styles.category}>{article?.category?.fields?.category?.toString()}</div>
            <h1 className={styles.articleTitle}>{article?.title}</h1>
            <div className={styles.articleHeaderImage}>
              <img src={article?.thumbnail?.fields?.file?.url as string || ""} alt={article?.title} />
            </div>
            <div className={styles.excerpt}>
              {article && documentToReactComponents(article.excerpt)}
            </div>
          </div>
        </header>
        <div className={styles.pageContentWrapper}>
          <section className={styles.articleContent}>
            {article && documentToReactComponents(article.content)}
          </section>
          {errorLoading && <h1>There was an error loading the articles</h1>}
          {loading && <div>Loading...</div>}
        </div>
    </article>
);
}
