import styles from "./articles.module.scss";
import {useContentful} from "contentfulHelper";
import {EntryFields, EntrySkeletonType,} from "contentful";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import FloatyMenu from "components/floatyMenu/FloatyMenu";

export default Articles;

type Article = {
  title: EntryFields.Text;
  slug: EntryFields.Text;
  thumbnail: EntryFields.AssetLink;
  createdAt: EntryFields.Date;
}

type ArticleQuery = EntrySkeletonType<Article>

function Articles() {
  const {authorized: articlesLoaded, client } = useContentful()
  const [articles, setArticles] = useState<Array<Article>>([])
  const [firstArticle, setFirstArticle] = useState<Article>()
  const [loading, setLoading] = useState(true)
  const [errorLoading, setErrorLoading] = useState(false)

  useEffect(() => {
    const res = client.getEntries<ArticleQuery>({
      content_type: "article"
    })
    res.then((res) => {
      setFirstArticle(res?.items?.shift()?.fields)
      setArticles(res.items.map((item) => item.fields))
    }).catch((err) => {
      setErrorLoading(true)
      console.log(err)
    }).finally(() => {
      setLoading(false)
    })
  }, [articlesLoaded])


  return (
  <>
    <div className={styles.articlePageWrapper}>
      <div className={styles.articlesWrapper}>
        <FloatyMenu/>
        <h1 className={styles.articlesPageHeader}>Articles</h1>
        <div className={styles.articles}>
          <div className={styles.mainArticle}>
            <Link to={"/articles/" + firstArticle?.slug} key={firstArticle?.slug}>
              <section className={styles.articleItem}>
                <img src={firstArticle?.thumbnail?.fields?.file?.url as string || ""} alt={firstArticle?.title} className={styles.thumbnail} />
                <h2 className={styles.itemTitle}>{firstArticle?.title}</h2>
              </section>
            </Link>
          </div>
          <div className={styles.articlesContainer}>
            {errorLoading && <h1>There was an error loading the articles</h1>}
            {loading && <div>Loading...</div>}
            {articles.map((article) => {
              return (
                <Link to={"/articles/" + article.slug} key={article.slug}>
                  <section className={styles.articleItem}>
                    <img src={article.thumbnail?.fields?.file?.url as string || ""} alt={article.title} className={styles.thumbnail} />
                    <h2 className={styles.itemTitle}>{article.title}</h2>
                  </section>
                </Link>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  </>
);
}
