import TitleSection from "components/titleSection/titleSection";
import CardSection from "components/cards/cardSection";
import Card from "components/cards/cardWrapper";
import PageContentWrapper from "pages/pageContentWrapper/PageContentWrapper";
import styles from "./Home.module.scss";
import { ReactComponent as AutomateImage } from "images/automate-everything.svg";
import { ReactComponent as MultiChannel } from "images/multi-channel.svg";
import { ReactComponent as MultiNode } from "images/multi-node.svg";
import { ReactComponent as OneLiner } from "images/one-liner.svg";
import { ReactComponent as DocumentationImage } from "images/single-doc.svg";
import { ReactComponent as GuidesVideos } from "images/guides-videos.svg";
// import PriceCard, {PriceCardWrapper} from "components/priceCard/PriceCard";
import SectionTitle from "components/sectionTitle/SectionTitle";
import {DocsItem, DocsSection} from "components/docsSection/DocsSection";
import FloatyMenu from "components/floatyMenu/FloatyMenu";
import {useEffect} from "react";

function Home() {

  useEffect(() => {
    // The first time the component is rendered, we want to scroll to the section with the id in the URL
    const url = window.location.href;
    const hash = url.split("#")[1];
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        // I also need to take into consideration the animation offset
        element.scrollIntoView();
      }
    }
  }, []);

  return (<div id={"home"}>
    <TitleSection />
    <FloatyMenu/>

    <PageContentWrapper>
      <SectionTitle title={
          <>{"The node management "}<br/>{"system "}<span className={styles.sectionTitleHighlight}>{"built for scale."}</span></>
      }
      prefix={"Features"}/>
      <CardSection>
        <Card
          title={<>{"1000+ channels?"}<br/>{"No problem."}</>}
          content={"Torq is built for industrial lightning nodes. See all your channels in less than a second. Inspect, manage, and update your channels with ease."}
          cardFigure={<MultiChannel/>}
        />
        <Card
          title={"Automate everything."}
          content={"We built the ultimate automation tool so you don’t have to. Open and Close channels, manage fees, and more - all from a simple interface."}
          cardFigure={<AutomateImage/>}
        />
        <Card
          title={"True Multi-Node and cross implementation support."}
          content={"Oversee and streamline multiple nodes from one central location. Simultaneously operate both LND and CLN to enhance redundancy and compatibility."}
          cardFigure={<MultiNode/>}
        />
      </CardSection>

      {/*<SectionTitle*/}
      {/*  id={"price"}*/}
      {/*  prefix={"Price"}*/}
      {/*  title={*/}
      {/*    <>{"Focus on what matters: "}<br/>*/}
      {/*    <span className={styles.sectionTitleHighlight}>{"your product."}</span></>*/}
      {/*  }*/}
      {/*/>*/}

      {/*<PriceCardWrapper>*/}
      {/*  <>*/}
      {/*  <PriceCard title={"Professional"} monthlyPrice={20} yearlyPrice={204} subtitle={"Perfect for startups or routing nodes."} features={*/}
      {/*    [*/}
      {/*        "1 node",*/}
      {/*        "50 channels",*/}
      {/*        "$100,000 capacity",*/}
      {/*        "LND & CLN (Cross implementation)",*/}
      {/*        "Industry leading analytics",*/}
      {/*        "Advanced workflow automation",*/}
      {/*        "Interactive workflow logs",*/}
      {/*    ]}*/}
      {/*  />*/}
      {/*  <PriceCard title={"Organization"} subtitle={"Take your lighting operation to the next level."} features={*/}
      {/*    [*/}
      {/*        "Everything in PRO",*/}
      {/*        "4 nodes",*/}
      {/*        "1500 channels",*/}
      {/*        "$5,000,000 capacity",*/}
      {/*        "Premium support",*/}
      {/*        "Role based access support (Coming soon)",*/}
      {/*        "API access (Coming soon)",*/}
      {/*    ]}*/}
      {/*  />*/}
      {/*  <PriceCard title={"Enterprise"} subtitle={"For large enterprise Lightning operations."} features={*/}
      {/*    [*/}
      {/*        "Everything in PRO",*/}
      {/*        "10 nodes",*/}
      {/*        "2500 channels",*/}
      {/*        "$20,000,000 capacity",*/}
      {/*        "Premium support",*/}
      {/*        "Prometheus + Grafana Integrations",*/}
      {/*        "Open Telemetry + Jaeger Integration",*/}
      {/*        "Role based access support (Coming soon)",*/}
      {/*        "API access (Coming soon)",*/}
      {/*        "LSP sales (Coming soon)",*/}
      {/*    ]}*/}
      {/*  />*/}
      {/*  </>*/}
      {/*</PriceCardWrapper>*/}

      <SectionTitle
        id={"get-started"}
        prefix={"Get started"}
        title={
          <>{"Everything you need to "}<br/>
          <span className={styles.sectionTitleHighlight}>{"get things rolling."}</span></>
        }
      />
      <DocsSection>
        <DocsItem link={"https://docs.torq.co/hc/docs/en/categories/install-torq"} title={"Install Torq."} body={"Install Torq with one single command. Or check out Kubernetes, podman, binary and other install options."} image={<OneLiner/>}/>
        <DocsItem link={"https://docs.torq.co"} title={"Documentation."} body={"See the full Torq documentation and learn how to use the automation workflows."} image={<DocumentationImage/>}/>
        <DocsItem link={"https://docs.torq.co/hc/docs/en/categories/tutorials"} title={"Guides and videos."} body={"Learn how to use Torq with our video guides."} image={<GuidesVideos/>}/>
      </DocsSection>
    </PageContentWrapper>
    </div>
  );
}

export default Home;