import { ReactNode } from "react";
import styles from "./cardSection.module.scss";

type cardProps = {
  children: ReactNode;
  id?: string;
};

export default function CardSection(props: cardProps) {
  // const sectionRef = useRef<HTMLDivElement>(null);
  //
  // useEffect(() => {
  //   if (window.location.hash === `#${props.id}` && sectionRef.current) {
  //     sectionRef.current.scrollIntoView({ behavior: "auto" });
  //   }
  // }, []);

  return (
    <div className={styles.cardSectionWrapper} id={props.id}>
      {props.children}
    </div>
  );
}
