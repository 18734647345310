import styles from "./testimonial.module.scss";


type TestimonialProps = {
  image: string;
  children?: React.ReactNode;
}
// const openQutation = "“";
// const closeQutation = "”";

// A testimonial component that has a heading a paragraph and an image.
export default function Testimonial(props: TestimonialProps) {
  return (
    <div className={styles.testimonialContainer}>
      <div className={styles.testimonialImage}>
        <img src={props.image} alt="Logo" />
      </div>
      {/*<div className={styles.testimonialContent}>*/}
      {/*  {props.children && (<div className={styles.testimonialParagraph}>*/}
      {/*    {openQutation}*/}
      {/*    {props.children}*/}
      {/*    {closeQutation}*/}
      {/*  </div>)}*/}
      {/*</div>*/}
    </div>
  );
}

export function TestimonialWrapper(props: { children: React.ReactNode }) {
  return (
    <div className={styles.testimonialGroupWrapper}>
      <div  className={styles.testimonialTitle}>
        {"Trusted by:"}
      </div>
      <div className={styles.testimonialContainerWrapper}>
        {props.children}
      </div>
    </div>
  )
}