import styles from "./titleSection.module.scss";
import deezy from "images/deezy-logo.svg";
import boltzLogo from "images/boltz-logo-alt.svg";
import lnmarketsLogo from "images/lnmarkets-logo.svg";
import Testimonial, {TestimonialWrapper} from "components/testimonial/Testimonial";;

export default function TitleSection() {

  return (
    <div className={styles.sectionWrapper}>
      <div className={styles.sectionBackgroundWrapper}>
        <div className={styles.sectionContainer}>
          <h1 className={styles.sectionTitle}>
            {"Enterprise-grade"}<br/>{" Lightning management."}
          </h1>
          <div className={styles.sectionContent}>
            {"Node management is crucial for every Lightning company. Whether you’re a payment processor, LSP, or exchange, "}
            <span className={styles.strongSectionContent}>{"Torq"}</span>
            {" helps you focus on what truly matters: "}
            <span className={styles.strongSectionContent}>{"your product."}</span>
          </div>
        </div>

      </div>
    <TestimonialWrapper>
      <Testimonial image={boltzLogo} />
      <Testimonial image={lnmarketsLogo} />
      <Testimonial image={deezy} />
    </TestimonialWrapper>
    </div>
  );
}
