import styles from "./DocsSection.module.scss";
import {ReactElement, ReactNode} from "react";

type DocsSectionProps = {
  children: ReactNode
}

export function DocsSection(props: DocsSectionProps) {
  return (
    <div className={styles.docsSectionWrapper}>
      {props.children}
    </div>
  );
}


type DocsItemProps = {
  title: string;
  body: string;
  link: string;
  image?: ReactElement;
}

export function DocsItem(props: DocsItemProps) {
  return (
    <a className={styles.docsSectionItemWrapper} href={props.link} rel={"noreferrer"}>
      <div className={styles.image}>{props.image}</div>
        <div className={styles.DocsContent}>
          <div className={styles.title}>{props.title}</div>
        <div className={styles.body}>{props.body}</div>
        </div>
    </a>
  );
}