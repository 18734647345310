import styles from "./footer.module.scss";
import {Link} from "react-router-dom";
import { ReactComponent as TorqLogo } from "images/torq-logo-black.svg";

export default function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.footerWrapper}>
        <Link className={styles.logo} to={"/"}>
          <TorqLogo />
        </Link>
        <div className={styles.footerContainer}>
          <a className={styles.footerLink} target={"_blank"} rel="noreferrer" href="https://t.me/+V-Dks6zjBK4xZWY0">Telegram</a>
            <a className={styles.footerLink} target={"_blank"} rel="noreferrer" href="https://twitter.com/torqln">Twitter</a>
            <a className={styles.footerLink} target={"_blank"} rel="noreferrer" href="https://github.com/lncapital/torq">Github</a>
            <a className={styles.footerLink} target={"_blank"} rel="noreferrer" href="https://docs.torq.co">Documentation</a>
            <a className={styles.footerLink} rel="noreferrer" href="https://app.termly.io/document/cookie-policy/c4098986-9b64-4b6c-b412-142b51cc73cb">Cookie Policy</a>
            <a className={styles.footerLink} rel="noreferrer" href="https://app.termly.io/document/terms-of-service/975ea7ab-7b4c-49fc-a6d5-17f19ca04aab">Terms & Conditions</a>
        </div>
      </div>
    </div>)
}