import {ReactNode, useEffect, useRef} from "react";
import styles from "./SectionTitle.module.scss";

type SectionTitleProps = {
  title: string | ReactNode;
  prefix?: string;
  id?: string;
}

export default function SectionTitle(props: SectionTitleProps) {
  const boxRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Function to execute when the box enters the viewport
    const handleIntersection: IntersectionObserverCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(styles.titleInView);
          // Optionally, you can stop observing after the first intersection
          observer.unobserve(entry.target);
        }
      });
    };

    // Initialize IntersectionObserver
    const observer = new IntersectionObserver(handleIntersection, {
      root: null, // Using viewport as root
      threshold: 0.1, // The box is considered in the viewport if it is 10% visible
    });

    // Start observing the box
    if (boxRef.current) {
      observer.observe(boxRef.current);
    }

    // Cleanup
    return () => {
      if (boxRef.current) {
        observer.unobserve(boxRef.current);
      }
    };
  }, []);

  return (
    <div className={styles.sectionTitleWrapper} ref={boxRef} id={props.id}>
      <div className={styles.sectionTitlePrefix}>{props.prefix}</div>
      <h2 className={styles.sectionTitle}>
        {props.title}
      </h2>
    </div>
  )

}